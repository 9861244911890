<template>
  <table class="table table-hover table-stripped table-head-fixed">
    <thead>
      <tr>
        <th class="text-center" scope="col">Titulo</th>
        <th class="text-center" scope="col">Filtro</th>
        <th class="text-center" scope="col">Inicio em</th>
        <th class="text-center" scope="col">Válido até</th>
        <th class="text-center" scope="col">Ações</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) of nps" :key="index">
        <th
          class="text-center"
          @click="$router.push({ name: 'nps.id', params: { id: item._id } })"
          scope="row"
        >
          {{ item.title }}
        </th>
        <td class="text-center">{{ item.filters.type }}</td>
        <td class="text-center">
          {{
            new Date(item.starts_in).toLocaleDateString("pt-BR", {
              hour: "numeric",
              minute: "numeric",
            })
          }}
        </td>
        <td class="text-center">
          {{
            new Date(item.expires_in).toLocaleDateString("pt-BR", {
              hour: "numeric",
              minute: "numeric",
            })
          }}
        </td>
        <td class="text-center">
          <button
									title="Baixar Nps" 
									class="btn btn-outline-primary btn-sm ml-1" 
									@click="exportNps(item._id)">
									<i class="fa fa-download"></i>
					</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  data() {
    return {
      nps: [],
    };
  },
  mounted() {
    this.$tallos_admin_api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.tokenJWT}`;
    this.getNps();
  },
  methods: {
    getNps: async function() {
      return await this.$tallos_admin_api
        .get("nps")
        .then((response) => {
          this.nps = response.data.nps;
          this.$emit("qtd_nps", response.data.nps.length);
        })
        .catch((error) => {
          this.$toasted.global.defaultError({
            message: "erro ao fazer upload",
            err: error,
          });
        });
    },
    exportNps(nps){
      this.$tallos_admin_api.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${this.$store.getters.tokenJWT}`;

      this.$tallos_admin_api
        .get(`${process.env.VUE_APP_URL_TALLOS_ADMIN_API}/nps/export-nps/${nps}`, {
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'Tallos Admin - NPS.xlsx';
          link.click();
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
tr {
  cursor: pointer;
}
</style>
